import { defineStore } from "pinia";

interface State {
  pageSize: "pc" | "phone";
}

export const usePublicStore = defineStore("public", {
  state: (): State => ({
    pageSize: "pc",
  }),
});
