import { customAlphabet } from "nanoid";

const nanoid = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", 21);

const sha256 = async (text: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(text);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
};

export const randsName = async (nameList: string[], randNumber: number) => {
  const id = nanoid();

  const nameHashList: { [key: string]: string } = {};
  for (const name of nameList) {
    nameHashList[await sha256(name + nanoid())] = name;
  }
  const hashList = Object.keys(nameHashList).sort(
    (a, b) => b.localeCompare(a)
  );

  // 抽取
  const seed_msg = id + nameList.length + randNumber;
  let seed_hex: string = "";
  const drawHash = new Set<string>();
  while (drawHash.size < randNumber) {
    seed_hex = await sha256(seed_hex === "" ? seed_msg : seed_hex);

    const seed = parseInt(seed_hex, 16);
    const index = seed % hashList.length;
    drawHash.add(hashList[index]);
  }
  
  return {
    id,
    results: Array.from(drawHash).map((v) => [v, nameHashList[v]]) as [string, string][],
  };
};
