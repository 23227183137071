<template>
  <n-config-provider :locale="zhCN" :date-locale="dateZhCN" :theme="darkTheme">
    <n-layout position="absolute">
      <n-layout-content :native-scrollbar="false" position="absolute">
        <div class="container" style="padding-top: 10rem; padding-bottom: 10px">
          <n-spin :show="showSpin || showModal">
            <template #description>loading~</template>
            <div>
              <div style="font-size: 1.8rem; display: flex; gap: 10px">
                <span>共 {{ nameData.length }} 人</span>
                <span v-if="randResult">{{ randResult.id }}</span>
              </div>
              <!-- 开始按钮 -->
              <n-button
                block
                round
                type="primary"
                style="height: 14rem; font-size: 9rem; font-weight: 300"
                @click="startButtonClick"
              >
                开始
              </n-button>
              <div
                style="
                  margin-top: 10px;
                  margin-bottom: 10px;
                  display: flex;
                  gap: 1rem;
                  flex-wrap: wrap;
                  justify-content: space-around;
                "
              >
                <n-card
                  v-for="(value, index) in randResult?.results"
                  :key="index"
                  hoverable
                  :bordered="false"
                  size="small"
                  style="width: 10rem"
                >
                  <span style="font-size: 2rem; display: flex; justify-content: center">{{ value[1] }}</span>
                </n-card>
              </div>
              <!-- 人数选择 -->
              <div style="height: 100%">
                <n-slider
                  v-model:value="randNum"
                  :marks="sliderMarks"
                  step="mark"
                  :min="1"
                  :max="10"
                  style="font-size: 2rem; width: 100%"
                />
              </div>
              <!-- <div
                style="
                  display: flex;
                  gap: 1rem;
                  margin-top: 3.8rem;
                  height: 5rem;
                  align-items: center;
                "
              >
                <n-button style="width: 17rem; height: 100%; font-size: 2.4rem">历史记录</n-button>
                <n-button style="width: 17rem; height: 100%; font-size: 2.4rem">修改密码</n-button>
              </div> -->
              <div style="margin-top: 3.8rem">
                <n-button size="large" @click="showModal = true">修改密码</n-button>
              </div>
            </div>
          </n-spin>
        </div>
      </n-layout-content>
    </n-layout>
    <n-modal v-model:show="showModal" preset="card" style="width: 50rem" size="huge" title="密码">
      <n-input v-model:value="passwordValue" type="text" size="large" />
      <div style="margin-top: 10px; display: flex; justify-content: end">
        <n-button type="primary" size="large" @click="main()">确定</n-button>
      </div>
    </n-modal>
    <n-global-style />
  </n-config-provider>
</template>

<script setup lang="ts">
import { h, ref } from "vue";
import { NButton, NInput, NCard, NModal, NSpin, NSlider, NLayout, NLayoutContent } from "naive-ui";
import { zhCN, dateZhCN, darkTheme, NGlobalStyle, NConfigProvider } from "naive-ui"; // NaiveUI Config
import { usePublicStore } from "@/stores";

import { randsName } from "@/assets/randsName";
import NaiveUIDiscreteAPI from "@/assets/NaiveUIDiscreteAPI";

const publicStore = usePublicStore();

const nameData = ref<string[]>([]);

const randNum = ref<number>(1);
const sliderMarks = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
};

const passwordValue = ref<string>();

const showSpin = ref<boolean>(false);
const showModal = ref<boolean>(true);

const randResult = ref<{
  id: string;
  results: [string, string][];
}>();

const main = async () => {
  NaiveUIDiscreteAPI.loadingBar.start();
  showSpin.value = true;

  const password = passwordValue.value ?? localStorage.getItem("dataName");
  if (!password) {
    showModal.value = true;
    NaiveUIDiscreteAPI.loadingBar.error();
    NaiveUIDiscreteAPI.message.warning("请输入密码");
    return;
  } else {
    localStorage.setItem("dataName", password);
    showModal.value = false;
  }

  try {
    const resp_json = await (await fetch(`/${password}.json`)).json();
    nameData.value = resp_json;
  } catch (e) {
    showModal.value = true;
    NaiveUIDiscreteAPI.loadingBar.error();
    NaiveUIDiscreteAPI.message.error("密码错误");
    return;
  }

  showSpin.value = false;
  NaiveUIDiscreteAPI.loadingBar.finish();
  NaiveUIDiscreteAPI.message.success("获取成功");
  passwordValue.value = undefined;
};
main();

const startButtonClick = async () => {
  const result = await randsName(nameData.value, randNum.value);
  randResult.value = result;
};

// 平台
// const resizeEvent = () => {
//   const pageWidth = window.innerWidth;

//   if (pageWidth < 700) {
//     publicStore.pageSize = "phone";
//   } else {
//     publicStore.pageSize = "pc";
//   }
// };
// window.addEventListener("resize", () => {
//   resizeEvent();
// });
// resizeEvent();
</script>

<style>
a {
  text-decoration: none;
}

body {
  overscroll-behavior-y: contain;
}

.siteName {
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 2px;
  white-space: nowrap;
  cursor: default;

  margin-bottom: 0;
}

.navigation {
  padding: 0 32px;
  height: calc(3.6rem - 1px);
  user-select: none;
  text-align: center;
  display: flex;
  align-items: center;
}

.container {
  /* max-width: 780px; */
  max-width: 50rem;
  margin: 0 auto;
}

.footer {
  height: calc(calc(3.6rem - 1px));
  user-select: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
